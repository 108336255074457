<template>
  <section class="container">
    <div class="profile"
         :class="{ 'padding-webview': checkWebview(), 'padding-has-caution': isHasCaution }">
      <h3 class="profile__title">{{ submitted ? '登録情報確認' : '登録情報変更' }}</h3>

      <ValidationObserver
        v-if="!submitted"
        tag="form"
        ref="observer"
        class="profile__block"
        @submit.prevent="validateBeforeSubmit()">

        <!--Title-->
        <div class="profile__subtitle">
          <h4>変更したい内容を変更の上、「同意し確認画面へ」ボタンを押してください。</h4>
        </div>

        <!--Error server-->
        <ul v-if="errors.length"
            class="error__server">
          <li v-for="(err, index) in errors"
              :key="index"
              v-text="err"/>
        </ul>

        <!--Form-->
        <div class="profile__form">
          <div class="profile__form__group">
            <!--name-->
            <div class="form__control">
              <label>
                ニックネーム
                <span class="required"
                      v-text="'必須'"/>
              </label>

              <div class="form__control__input">
            <span class="required"
                  v-text="'必須'"/>

                <InputField v-model="form.nickname"
                            rules="required|max:10"
                            class="w-100"
                            field="ニックネーム"
                            vid="nickname"/>
              </div>
            </div>

            <!--email-->
            <div class="form__control">
              <label>
                メールアドレス
                <span class="required"
                      v-text="'必須'"/>
              </label>

              <div class="form__control__input">
            <span class="required"
                  v-text="'必須'"/>

                <InputField v-model="form.email"
                            class="w-100"
                            field="メールアドレス"
                            vid="email"
                            rules="email|max:200"
                            :disabled="true"/>
              </div>
            </div>
          </div>
          <div class="profile__form__group">
            <!--gender-->
            <div class="form__control form__control--specify mb-4">
              <label>
                性別
                <span class="required"
                      v-text="'必須'"/>
              </label>

              <div class="form__control__input">
            <span class="required"
                  v-text="'必須'"/>

                <div class="radio__field">
                  <div v-for="item in OPTION_GENDER"
                       :key="item.id">
                    <input v-model="form.gender"
                           type="radio"
                           :id="item.text"
                           :value="item.value">
                    <label :for="item.text"
                           v-text="item.text"
                           class="ml-2"/>
                  </div>
                </div>
              </div>
            </div>

            <!--birthday-->
            <div class="form__control ">
              <label>
                生年月日
              </label>

              <div class="form__control__input">
                <span v-if="(profile && !profile.birthday)" class="required"
                  v-text="'必須'"/>
                <InputField v-model="form.birthday"
                          field="生年月日"
                          :rules="(profile && profile.birthday) ? '' : 'required'"
                          :disabled="(profile && profile.birthday) ? true : false"
                          vid="birthday"
                          @focusout="handleFocusout()"
                          @focusin="handleFocusin()"
                          placeholder="例）19900701"
                          :class="['w-30', 'postal_code', 'errors-nowrap', {'no-required': profile && profile.birthday}]"/>
                </div>
            </div>

            <!--postal_code-->
            <div class="form__control">
              <label>
                郵便番号
              </label>

              <div class="form__control__input">
                <span class="required"
                  v-text="'必須'"/>
                <InputField v-model="form.postcode"
                            class="w-30 postal_code errors-nowrap"
                            rules="required|numeric|postCodeNum:7"
                            field="郵便番号"
                            vid="postcode"
                            :max-length="7"/>
              </div>
            </div>
          </div>
          <div class="profile__form__group">
            <!--secret_question-->
            <div class="form__control form__control--select ">
              <label>
                秘密の質問
                <span class="required"
                      v-text="'必須'"/>
              </label>

              <div class="form__control__input">
            <span class="required"
                  v-text="'必須'"/>
                <InputSelect v-model.number="form.secret_question"
                             field="秘密の質問"
                             vid="secret_question"
                             rules="required"
                             class="w-50 errors-nowrap"
                             :options="OPTION_SECRET_QUESTION"/>
              </div>
            </div>

            <!--secret_answer-->
            <div class="form__control">
              <label>
                秘密の答え
                <span class="required"
                      v-text="'必須'"/>
              </label>

              <div class="form__control__input">
            <span class="required"
                  v-text="'必須'"/>

                <InputField v-model="form.secret_answer"
                            class="w-100"
                            rules="required|minmax:2,10|only_hiragana"
                            field="秘密の答え"
                            vid="secret_answer"/>
              </div>
            </div>
          </div>
          <div class="profile__form__group">
            <!--career-->
            <div class="form__control form__control--select ">
              <label v-text="'職業区分'"/>

              <div class="form__control__input">
                <InputSelect v-model.number="form.career"
                             field="職業区分"
                             vid="career"
                             class="w-50 no-required"
                             :options="OPTION_CAREER"/>
              </div>
            </div>

            <!--income_of_family-->
            <div class="form__control form__control--select ">
              <label v-text="'世帯年収'"/>

              <div class="form__control__input">
                <InputSelect v-model.number="form.income_of_family"
                             field="世帯年収"
                             vid="income_of_family"
                             class="w-50 no-required"
                             :options="OPTION_INCOME_OF_FAMILY"/>
              </div>
            </div>

            <!--marriage_status-->
            <div class="form__control form__control--specify mb-4">
              <label v-text="'未既婚'"/>

              <div class="form__control__input">
                <div class="radio__field no-required">
                  <div v-for="item in OPTION_MARRIAGE"
                       :key="item.id">
                    <input v-model="form.marriage_status"
                           type="radio"
                           :id="item.text"
                           :value="item.value">
                    <label :for="item.text"
                           v-text="item.text"
                           class="ml-2"/>
                  </div>
                </div>
              </div>
            </div>

            <!--person_living_together-->
            <div class="form__control">
              <label v-text="'同居人数'"/>

              <div class="form__control__input">
                <InputField v-model.number="form.person_living_together"
                            class="w-30 no-required member-family errors-nowrap"
                            rules="only_number_halfwidth|max:15"
                            field="同居人数"
                            vid="person_living_together"
                            :max-length="15"/>
                <span class="people">人</span>
              </div>
            </div>

            <!--residence_status-->
            <div class="form__control form__control--select ">
              <label v-text="'居住形態'"/>

              <div class="form__control__input">
                <InputSelect v-model.number="form.residence_status"
                             field="居住形態"
                             vid="residence_status"
                             class="w-50 no-required"
                             :options="OPTION_RESIDENCE"/>
              </div>
            </div>

            <!--interest-->
            <div class="form__control form__control--select ">
              <label v-text="'興味のある分野'"/>

              <div class="form__control__input">
                <div class="checkbox__field">
                  <div v-for="item in OPTION_INTEREST" :key="item.id">
                    <input v-model="form.interest" type="checkbox" :id="item.id" :value="item.value">
                    <label :for="item.id">{{ item.text }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="profile__form__group">
            <!--invite_code-->
            <div class="form__control">
              <label v-text="'紹介コード'"/>

              <div class="form__control__input">
                <InputField :value="profile.invite_code ? profile.invite_code : ''"
                            class="w-100 no-required"
                            placeholder="お持ちの方はご入力ください"
                            field="紹介コード"
                            :disabled="true"
                            vid="invite_code"/>
              </div>
            </div>
          </div>
        </div>

        <!--button submit-->
        <button type="submit"
                class="btn btn__submit">
          同意し確認画面へ
          <ChevronRightIcon size="24"/>
        </button>
      </ValidationObserver>

      <div v-else
           class="profile__block">
        <!--Title-->
        <div class="profile__subtitle">
          <h4>入力内容を確認してください。内容をご確認の上、よろしければ「上記内容で変更する」ボタンを押してください。</h4>
        </div>

        <!--Step form-->
        <div class="profile__form">
          <div class="profile__form__group">
            <div class="item">
              <label v-text="'ニックネーム'"/>

              <p v-text="form.nickname || '－'"/>
            </div>

            <div class="item">
              <label v-text="'メールアドレス'"/>

              <p v-text="profile.email"/>
            </div>
          </div>
          <div class="profile__form__group">
            <div class="item">
              <label v-text="'性別'"/>

              <p>{{ form.gender | findTextEnum(OPTION_GENDER)}}</p>
            </div>

            <div class="item">
              <label v-text="'生年月日'"/>

              <p v-text="form.birthday || '－'"/>
            </div>

            <div class="item">
              <label v-text="'郵便番号'"/>

              <p v-text="form.postcode || '－'"/>
            </div>
          </div>
          <div class="profile__form__group">
            <div class="item">
              <label v-text="'秘密の質問'"/>

              <p>{{ form.secret_question | findTextEnum(OPTION_SECRET_QUESTION) }}</p>
            </div>

            <div class="item">
              <label v-text="'秘密の答え'"/>

              <p v-text="form.secret_answer"/>
            </div>
          </div>
          <div class="profile__form__group">
            <div class="item">
              <label v-text="'職業区分'"/>

              <p>{{ form.career | findTextEnum(OPTION_CAREER) }}</p>
            </div>

            <div class="item">
              <label v-text="'世帯年収'"/>

              <p>{{ form.income_of_family | findTextEnum(OPTION_INCOME_OF_FAMILY) }}</p>
            </div>

            <div class="item">
              <label v-text="'未既婚'"/>

              <p>{{ form.marriage_status | findTextEnum(OPTION_MARRIAGE) }}</p>
            </div>

            <div class="item">
              <label v-text="'同居人数'"/>

              <p v-text="form.person_living_together ? (form.person_living_together + '人') : '－'"/>
            </div>

            <div class="item">
              <label v-text="'居住形態'"/>

              <p>{{ form.residence_status | findTextEnum(OPTION_RESIDENCE) }}</p>
            </div>

            <div class="item">
              <label v-text="'興味のある分野'"/>

              <ul>
                <template v-if="form.interest.length">
                  <li v-for="(item, index) in form.interest" :key="index">{{OBJECT_INTEREST[item]}}</li>
                </template>
                <template v-else>
                  －
                </template>
              </ul>
            </div>
          </div>
          <div class="profile__form__group">
            <div class="item">
              <label v-text="'紹介コード'"/>

              <p v-text="profile.invite_code || '－'"/>
            </div>
          </div>
        </div>

        <!--button submit-->
        <div class="btn__group">
          <a @click.prevent="submitted = false"
             href="#"
             class="btn btn__back">
            <ChevronLeftIcon size="24"/>
            入力画面へ戻る
          </a>

          <button @click.prevent="handleSubmit()"
                  class="btn btn__submit">
            上記内容で変更する
            <ChevronRightIcon size="24"/>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// Store
import { mapActions, mapState } from 'vuex'
// Components
import InputSelect from '@/components/form/InputSelect'
import InputField from '@/components/form/InputField'
// Other
import {
  OPTION_CAREER,
  OPTION_GENDER,
  OPTION_INCOME_OF_FAMILY,
  OPTION_INTEREST,
  OPTION_MARRIAGE,
  OPTION_RESIDENCE,
  OPTION_SECRET_QUESTION,
  OBJECT_INTEREST
} from '@/enum/options'
import { ChevronRightIcon, ChevronLeftIcon } from 'vue-feather-icons'
import store from '@/store'
import Cookie from 'js-cookie'
import Common from '@/mixins/common.mixin'
import { checkBirthdayInvalid, checkDateInvalid, formatDateToString } from '@/utils/helpers'
import moment from 'moment'

export default {
  name: 'ChangeProfile',

  components: {
    InputSelect,
    InputField,
    ChevronLeftIcon,
    ChevronRightIcon
  },

  mixins: [Common],

  data () {
    return {
      form: {
        nickname: '',
        email: '',
        gender: 2,
        birthday: null,
        postcode: '',
        secret_question: null,
        secret_answer: '',
        career: null,
        income_of_family: null,
        marriage_status: 1,
        person_living_together: null,
        residence_status: null,
        interest: [],
        token: ''
      },
      submitted: false,
      errors: [],
      OPTION_GENDER,
      OPTION_SECRET_QUESTION,
      OPTION_CAREER,
      OPTION_INCOME_OF_FAMILY,
      OPTION_MARRIAGE,
      OPTION_RESIDENCE,
      OPTION_INTEREST,
      OBJECT_INTEREST
    }
  },

  beforeRouteEnter (to, from, next) {
    store.dispatch('auth/userProfileMore').then(_ => next())
  },

  created () {
    if (this.checkWebview() && !Cookie.get('bearer_token')) {
      this.openPageNative('Login')
    }
    this.form = {
      ...this.form,
      ...this.profile,
      birthday: this.profile.birthday
        ? moment(this.profile.birthday.replaceAll('-', '')).format('YYYY年MM月DD日') : null
    }
  },

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {
        isProfile: true
      })
    }
  },

  computed: {
    ...mapState('auth', ['profile']),
    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    }
  },

  watch: {
    submitted: {
      handler () {
        this.scrollToTop()
      }
    }
  },

  methods: {
    // Action
    ...mapActions('user', ['changeProfile']),
    ...mapActions('auth', ['userProfile']),

    handleFocusout () {
      if (this.form.birthday) {
        // validate field birthday
        if (checkBirthdayInvalid(this.form.birthday)) {
          this.$refs.observer.setErrors({
            birthday: '半角数字8桁で入力してください。'
          })
        } else {
          // check field birthday is invalid date
          if (checkDateInvalid(this.form.birthday)) {
            this.$refs.observer.setErrors({
              birthday: '正しい日付を入力してください。'
            })
          } else this.form.birthday = moment(this.form.birthday).format('YYYY年MM月DD日')
        }
      }
    },

    handleFocusin () {
      if (this.form.birthday) {
        this.form.birthday = this.form.birthday.replace(/\D/g, '')
      }
    },

    async validateBeforeSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        this.submitted = true
      }
      this.scrollToTop()
    },

    handleSubmit () {
      this.form = {
        ...this.form,
        // format date before submit API
        birthday: this.form.birthday ? formatDateToString(this.form.birthday) : null,
        skip_regis_info: +(!this.form.secret_answer || !this.form.postcode || !this.form.birthday || !this.form.secret_question)

      }
      this.changeProfile(this.form).then(result => {
        if (result.status === 204) {
          // send nick name to app
          if (this.checkWebview()) {
            this.triggerEventApp('CHANGE_NICKNAME', {
              nickname: this.form.nickname
            })
          }

          // fetch api me to refresh profile
          this.userProfile().then(result => {
            if (this.$route.params && this.$route.params.isChangePoint && this.$route.params.name) {
              this.$router.push({ name: this.$route.params.name })
            } else {
              this.openPageNative('Index')
            }
          })
          // this.userProfile().then(this.openPageNative('Index'))
        } else {
          this.handleErrors(result)
        }
      })
    },

    handleErrors (result) {
      this.errors = []

      if (result.status === 422) {
        // Back to tab form
        this.submitted = false
        // Scroll top
        this.scrollToTop()
        // Set errors server
        result.data.errors.forEach(x => {
          this.errors.push(x.message[0])
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media #{$info-phone} {
  .container {
    padding: 0;
  }
}
.profile {
  padding: 65px 0;
  &.padding-has-caution {
    @media #{$info-phone} {
      padding: 110px 0 65px;
    }
  }
  &.padding-webview {
    padding: 20px 0 0;
  }

  &__title {
    font-family: $font-family-title;
    color: $default-green;
    @include font-size(36px);
    font-weight: 400;
    text-align: center;
    margin-bottom: 40px;
    @media #{$info-phone} {
      font-size: 32px;
      margin-bottom: 30px;
    }
  }

  &__subtitle {
    text-align: center;
    h4 {
      font-family: $font-family-title;
      font-weight: normal;
      @include font-size(24px);
      color: #7C934C;
      margin-bottom: 50px;
      @media #{$info-phone} {
        font-size: 20px;
        margin-bottom: 40px;
      }
    }
    p {
      @include font-size(24px);
      font-family: $font-family-title;
      font-weight: 500;
      color: $default-green;
      margin-bottom: 50px;
      @media #{$info-phone} {
        text-align: left;
        margin-bottom: 40px;
      }
    }
  }

  &__block {
    padding: 65px;
    @media #{$info-phone} {
      padding: 48px 18px;
    }
    border: 6px solid #E7E5DE;
    border-radius: 6px;
    background-color: #FAFAFA;
    @media #{$info-phone} {
      border: 3px solid #E7E5DE;
      border-left: none;
      border-right: none;
      border-radius: 0;
    }
  }

  &__form {
    background-color: #E7E5DE;
    border-radius: 6px;
    margin-bottom: 60px;
    @media #{$info-phone} {
      margin-bottom: 45px;
    }
  }
  &__form__group {
    padding: 24px;
    @media #{$info-phone} {
      padding: 36px 18px;
    }
    .form__control {
      display: flex;
      align-items: flex-start;
      @media #{$info-phone} {
        display: block;
      }
      label {
        width: 235px;
        flex-shrink: 0;
        @include font-size(18px);
        margin-top: 13px;
        @media #{$info-phone} {
          width: 100%;
          font-size: 16px;
          display: block;
          margin-bottom: 12px;
        }
        .required {
          display: none;
          color: #C66C44;
          @include font-size(14px);
          font-weight: bold;
          font-family: $font-family-title;
          margin-top: 16px;
          margin-left: 8px;
          @media #{$info-phone} {
            display: inline-block;
          }
        }
      }
      p {
        margin-top: 16px;
        margin-left: 50px;
        @media #{$info-phone} {
          margin-left: 0;
        }
      }
      &--specify {
        label {
          margin-top: 3px;
          @media #{$info-phone} {
            margin-top: 0;
          }
        }
        .form__control__input {
          .required {
            margin-top: 3px;
          }
        }
      }
      &--select {
        label {
          margin-top: 6px;
          @media #{$info-phone} {
            margin-top: 0;
          }
        }
        .form__control__input {
          .required {
            margin-top: 7px;
          }
        }
      }
    }
    .form__control:not(:last-child) {
      margin-bottom: 18px;
      @media #{$info-phone} {
        margin-bottom: 30px;
      }
    }
    .form__control__input {
      display: flex;
      align-items: flex-start;
      flex: 1;
      .required {
        color: #C66C44;
        @include font-size(14px);
        font-weight: bold;
        flex-shrink: 0;
        margin-right: 24px;
        margin-top: 16px;
        @media #{$info-phone} {
          display: none;
        }
      }
      .no-required {
        margin-left: 52px;
        @media #{$info-phone} {
          margin-left: 0;
        }
      }
      .people {
        margin-top: 12px;
        margin-left: 7px;
      }
      .radio__field {
        display: flex;
        flex-wrap: wrap;
        div {
          display: flex;
          align-items: center;
          margin: 0;
        }
        label {
          width: unset;
          margin: 0;
          vertical-align: middle;
          @media #{$info-phone} {
            display: inherit;
            margin-bottom: 0;
          }
        }
        input {
          flex-shrink: 0;
        }
        &>div {
          margin-right: 20px;
          @media #{$info-phone} {
            display: flex;
            align-items: center;
          }
        }
      }
      .postal_code,
      .birthday {
        @media #{$info-phone} {
          width: 50%;
        }
      }
    }

    .item {
      display: flex;
      align-items: center;
      @media #{$info-phone} {
        display: block;
      }
      label {
        width: 235px;
        flex-shrink: 0;
        @include font-size(18px);
        @media #{$info-phone} {
          width: 100%;
          font-size: 16px;
          display: block;
          margin-bottom: 12px;
        }
      }
      p {
        flex: 1;
        border-radius: 6px;
        background: rgba(255,255,255,.35);
        color: #282828;
        font-family: $font-family-title;
        @include font-size(20px);
        padding: 12px 16px;
        @media #{$info-phone} {
          font-size: 16px;
          padding: 10px 18px;
        }
      }
      ul {
        flex: 1;
        background: rgba(255,255,255,.35);
        border-radius: 6px;
        padding: 15px;
        li {
          color: #282828;
          display: block;
          font-size: 18px;
          @media #{$info-phone} {
            font-size: 16px;
          }
        }
      }
    }
    .item:not(:last-child) {
      margin-bottom: 18px;
      @media #{$info-phone} {
        margin-bottom: 30px;
      }
    }
  }
  &__form__group:not(:last-child) {
    border-bottom: 1px solid #ffffff;
  }

  .btn__submit {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 360px;
    width: 100%;
    border-radius: 6px;
    color: #ffffff;
    background: #6F8784;
    font-family: $font-family-title;
    @include font-size(18px);
    padding: 14px;
    margin: auto;
    cursor: pointer;
    @media #{$info-phone} {
      font-size: 16px;
      padding: 10px;
      max-width: 100%;
    }
    svg {
      margin-left: 5px;
    }
  }

  .btn__group {
    position: relative;
    @media screen and (max-width: 992px) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }
    .btn__back {
      position: absolute;
      top: 12px;
      display: flex;
      align-items: center;
      @include font-size(16px);
      font-family: $font-family-title;
      color: #282828;
      @media screen and (max-width: 992px) {
        position: static;
        margin-top: 30px;
      }
      svg {
        margin-right: 5px;
      }
    }
  }
}

</style>
